export class EventEmitter {
    constructor(name) {
        this._event = undefined;
        this._callbackList = [];
        this._symbol = Symbol(name || 'event-emitter-uniq');
    }
    get event() {
        if (this._event === undefined) {
            this._event = (callback) => {
                this._callbackList.push(callback);
                return {
                    dispose: () => {
                        const index = this._callbackList.findIndex((c) => c === callback);
                        this._callbackList.splice(index, 1);
                    },
                };
            };
        }
        return this._event;
    }
    get symbol() {
        return this._symbol;
    }
    emit(params) {
        this._callbackList.forEach(callback => {
            callback.call(null, params);
        });
    }
    equal(event) {
        return event.symbol === this.symbol;
    }
    dispose() {
        this._event = undefined;
        this._callbackList = [];
    }
}
export function EventEmitterHandle() {
    const emitter = new EventEmitter();
    const _callbackMap = new Map();
    const event = (callback) => {
        if (!callback)
            return;
        const disposeObj = emitter.event(callback);
        _callbackMap.set(callback, disposeObj.dispose);
    };
    const off = (callback) => {
        if (!callback)
            return;
        const _dispose = _callbackMap.get(callback);
        if (!_dispose)
            return;
        _dispose();
        _callbackMap.delete(callback);
    };
    const emit = () => {
        emitter.emit();
    };
    const dispose = () => {
        _callbackMap.clear();
        emitter.dispose();
    };
    return {
        event,
        off,
        emit,
        dispose
    };
}
