import { EventEmitter } from './event';
import { DisposableMap } from './dispose';
export var eventBus;
(function (eventBus) {
    const busSet = new Set();
    function Events() {
        const emitters = new Map();
        const disposes = new DisposableMap();
        const addListener = (key, fn) => {
            const emitter = emitters.get(key) || new EventEmitter();
            emitters.set(key, emitter);
            const disposable = emitter.event(fn);
            disposes.addDispose(fn, disposable);
        };
        const removeListener = (key, fn) => {
            if (!fn) {
                const emitter = emitters.get(key);
                emitters.delete(key);
                if (emitter)
                    emitter.dispose();
            }
            else {
                disposes.remove(fn);
            }
        };
        const emit = (key, params) => {
            const emitter = emitters.get(key);
            if (emitter)
                emitter.emit(params);
        };
        const send = (key, params) => {
            for (const bus of busSet.values()) {
                bus.emit(key, params);
            }
        };
        const dispose = () => {
            disposes.dispose();
            busSet.delete(self);
        };
        const self = {
            emit,
            send,
            addListener,
            removeListener,
            dispose,
        };
        return self;
    }
    function create() {
        const bus = Events();
        busSet.add(bus);
        return bus;
    }
    eventBus.create = create;
})(eventBus || (eventBus = {}));
