export class Disposable {
    dispose() {
        // nothing
    }
}
export class DisposableCollection {
    constructor() {
        this._list = [];
    }
    addDispose(disposable) {
        this._list.push(disposable);
    }
    clear() {
        this._list = [];
    }
    dispose() {
        this._list.forEach((disposable) => {
            disposable.dispose();
        });
        this.clear();
    }
}
export class DisposableMap {
    constructor() {
        this._map = new Map();
    }
    addDispose(hash, disposable) {
        this._map.set(hash, disposable);
    }
    clear() {
        this._map.clear();
    }
    remove(hash) {
        const disposable = this._map.get(hash);
        if (disposable) {
            this._map.delete(hash);
            disposable.dispose();
        }
    }
    dispose() {
        for (const disposable of this._map.values()) {
            disposable.dispose();
        }
        this.clear();
    }
}
